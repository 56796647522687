import api from '../services/api';

export const getFitnessPrograms = () => {
    return api.get('/fitness_programs').then((res) => res.data.data);
};
export const getFitnessProgramsQuestions = (_url: string, id: string) => {
    return api
        .get('/questions', {
            params: {
                'filter[fitness_program_id]': id,
            },
        })
        .then((res) => res.data.data);
};
export const getFitnessPlans = (_url: string, id: string) => {
    return api
        .get('/fitness_plans', {
            params: {
                'filter[fitness_program_id]': id,
            },
        })
        .then((res) => res.data.data);
};

export const getFitnessWeeks = () => {
    return api.get('/weeks').then((res) => res.data.data);
};

export const getFitnessWeeksNew = () => {
    return api.get('/current-plan/weeks').then((res) => res.data.data);
};

export const updateFitnessWeeks = (data: { id: number; day?: number; slot?: number }[]) => {
    return api.post('user-fitness-day/reorder', { user_trainings: data }).then((res) => res.data.data);
};

export const selectFitnessPlan = (id: number) => {
    return api
        .post('/fitness_plans', {
            fitness_plan_id: id,
        })
        .then((res) => res.data.data);
};
export const setFitnessPlanFirstDay = (id: number, day: string) => {
    return api
        .post(`/fitness_plans`, {
            fitness_plan_id: id,
            start_date: day,
        })
        .then((res) => res.data.data);
};

export const getFitnessPlan = (_url: string, id: number) => {
    return api.get(`/fitness_plans/${id}`).then((res) => res.data.data);
};

export const updateTraining = (
    id: number,
    values: { done_at?: string | null; day?: number; slot?: number; skipped?: boolean },
) => {
    return api.post(`/fitness_trainings/${id}/update`, values);
};

export const getTraining = (_url: string, id: string) => {
    return api.get(`/fitness_trainings/${id}`).then((res) => res.data.data);
};

export const replanTrainingFuture = (values: { to_date: string; move_from: string }) => {
    return api.post(`/user-fitness-day/re-plan/future`, values);
};

export type FitnessProgramGuestions = {
    id: number;
    question: string;
    description?: string;
    answers: {
        id: number;
        answer: string;
    }[];
};
export type FitnessWeekTraining = {
    color: number;
    day: number;
    name: string;
    done_at: string | null;
    id: number;
    slot: number;
    training_0_id: number;
    training_1_id: number;
};
export type FitnessWeek = {
    id: number;
    fitness_trainings: FitnessWeekTraining[];
};

export type FitnessWeekDayTraining = {
    id: string;
    slot: string;
    color: string;
    color_hex: string;
    training_0_id: string;
    training_1_id: string;
    training_id: string;
    fitness_plan_user_i: string;
    done_at: string;
    date: string;
    skipped?: boolean;
    name: string;
    training_home: {
        id: string,
        name: string,
        coach_name: string,
        coach_image_url:string,
        duration: string
    },
    training_gym: {
        id: string,
        name: string,
        coach_name: string,
        coach_image_url: string,
        duration: string
    }

}

export type FitnessWeekDay = {
    date: string;
    type: string;
    trainings: FitnessWeekDayTraining[];
    status: string;
}

export type FitnessWeekNew = {
    week_number: string;
    first_day: string;
    days: FitnessWeekDay[];
}

export type TrainingColor = 'yellow' | 'pink' | 'blue' | 'purple' | 'black' | 'grey';
export const trainingTypes: Record<string, { color: TrainingColor }> = {
    '0': {
        color: 'yellow',
    },
    '1': {
        color: 'pink',
    },
    '2': {
        color: 'blue',
    },
    '3': {
        color: 'purple',
    },
    '4': {
        color: 'black',
    },
    '5': {
        color: 'grey',
    },
};

export type TrainingPhaseType = 0 | 1 | 2;
// TYPE_EXERCISE = 0,
// TYPE_WARMUP = 1,
// TYPE_STRETCH = 2,
