import { mutate } from 'swr';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { mutateCallback } from 'swr/dist/types';

import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { FitnessWeekDayTraining, FitnessWeekNew, replanTrainingFuture, updateTraining } from '../../../moduls/trainings';
import useNotification from '../../../hooks/useNotification';

interface Props {
    isOpen: boolean;
    moveFrom?: string;
    setClose: () => void;
    mutateFitnessWeeks: (
        data?: FitnessWeekNew[] | Promise<FitnessWeekNew[]> | mutateCallback<FitnessWeekNew[]> | undefined,
        shouldRevalidate?: boolean,
    ) => Promise<FitnessWeekNew[] | undefined>;
    skippedTrainings: FitnessWeekDayTraining[];
}

const MissedSomeTrainingsModal = (props: Props) => {
    const { open: openNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal isOpen={props.isOpen} close={() => {}} hideCloseButton>
            <h3 className="h-mb-3">Vynechala jsi pár tréninků. Žádný stres, co chceš udělat dál?</h3>
            <div className="btn-column">
                <Button
                    size="small"
                    color="gray"
                    loading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        await Promise.all(
                            props.skippedTrainings.map(async (training) => {
                                const id = parseInt(training.training_id ?? '0');
                                await updateTraining(id, { done_at: null, skipped: true }).catch(() => {
                                    openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                                });
                            }),
                        );

                        await mutate('fitness-plan');
                        await props.mutateFitnessWeeks();
                        props.setClose();
                    }}
                >
                    Vynechat je
                </Button>
                <Button
                    size="small"
                    color="gray"
                    loading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        await replanTrainingFuture({
                            move_from: props.moveFrom ?? format(new Date(), 'Y-MM-dd'),
                            to_date: format(new Date(), 'Y-MM-dd'),
                        }).catch(() => {
                            openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                        });

                        await mutate('fitness-plan');
                        await props.mutateFitnessWeeks();
                        props.setClose();
                    }}
                >
                    Přesunout tréninky ode dneška
                </Button>
            </div>
        </Modal>
    );
};

export default MissedSomeTrainingsModal;
