import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useSupportbox from '../../../hooks/useSupportbox';
import AppLoader from '../../../components/UI/Loader/AppLoader';

const Chat: React.FC<RouteComponentProps> = (props) => {
    const [erroe, setErroe] = useState(false);
    useSupportbox({ support_box: true } as any);

    const query = new URLSearchParams(props.location.search);

    useEffect(() => {
        if (!query.get('name') || !query.get('email')) {
            window.smartsupp('chat:hide');
            setErroe(true);
            return;
        }

        window.smartsupp('chat:open');
        window.smartsupp('name', query.get('name'));
        window.smartsupp('email', query.get('email'));
        window.smartsupp('on', 'messenger_close', function (message: any) {
            // @ts-ignore
            if (window.onMessengerClose) {
                // @ts-ignore
                window.onMessengerClose.postMessage('close');
            }
        });

        const container = document.body;

        const onChildChange = () => {
            const container = (document.querySelector('#smartsupp-widget-container') as HTMLElement)
                .children[0] as HTMLElement;

            container.style.width = '100%';
            container.style.height = '100%';
            container.style.left = '0';
            container.style.top = '0';
            container.style.maxHeight = '100%';
            container.style.borderRadius = '0';
        };

        const callback = (mutationList: MutationRecord[], observer: MutationObserver) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    onChildChange();
                }
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(container, { attributes: true, childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    return erroe ? <div>Chybi povinne parametry</div> : <AppLoader />;
};

export default Chat;
