import React, { useState } from 'react';
import './Overview.scss';
import Button from '../../components/UI/Button/Button';
import OverviewList from '../../components/OverviewList/OverviewList';
import { routesURLs } from '../../Routes';
import News from './News/News';
import Tutorial from './Tutorial/Tutorial';
import useProfile from '../../hooks/useProfile';
import useTranslations from '../../hooks/useTranslations';
import useFitnessPlan from '../../hooks/useFitnessPlan';
import { apiErrors } from '../../services/api';
import { useFitnessWeeksNew } from '../../hooks/useFitnessWeeks';

const Overview = () => {
    const { data: translations } = useTranslations();
    const { isCoach, isTrainingPlan, data: profile } = useProfile();
    const {
        data: fitnessPlan,
        error: fitnessPlanError,
        isValidating: validationFitnessPlan,
    } = useFitnessPlan(profile?.fitness_plan_id as number | undefined);
    const [showTutorial, setShowTutorial] = useState(!JSON.parse(localStorage.getItem('tutorial-shown')!) && !profile?.tutorial_seen);

    const { data: fitnessPlanWeeksNew } = useFitnessWeeksNew();

    return (
        <>
            <div className='overview dashboard-grid page-row container'>
                <div className='dashboard-side-text'>
                    <div className='overview-text'>
                        <h1>Nástěnka</h1>
                        <p dangerouslySetInnerHTML={{ __html: translations?.[0] ?? '...' }}></p>
                        <Button onClick={() => setShowTutorial(true)} icon='play-white' color='black' size='small'>
                            Jak to tady funguje
                        </Button>
                    </div>
                </div>
                <div className='dashboard-content'>
                    <div className='container-row center-lg'>
                        <div className='col-md-6'>
                            <OverviewList
                                items={
                                    fitnessPlanError
                                        ? []
                                        : [
                                            {
                                                name: fitnessPlan ? 'Změnit' : 'Zvolit',
                                                route: routesURLs.fitnessPrograms,
                                            },
                                            {
                                                name: 'Kvíz pro správný výběr',
                                                route: 'https://www.strongbeauty.cz/kviz-vyber-fitness-programu/',
                                                target: '_blank',
                                            },
                                        ]
                                }
                                color='yellow'
                                buttonIcon='dumbbells-black'
                                route={''}
                                loading={validationFitnessPlan}
                                className='h-mb-15'
                                button={<span />}
                                buttonChildren={
                                    <div className='overview-list-button'>
                                        <i className={`icon-dumbbells-black`} />
                                        <div className='h-text-left h-flex-grow-1'>
                                            <h5 className='h-mb-0 h-text-regular'>Aktuální fitness program:</h5>
                                            {fitnessPlanError ? (
                                                <div className='h-mb-0 h-text-size-medium'>
                                                    Nedostupný (
                                                    {
                                                        apiErrors[
                                                            fitnessPlanError.response.status as keyof typeof apiErrors
                                                            ]
                                                    }
                                                    )
                                                </div>
                                            ) : (
                                                <div className='h-mb-0'>
                                                    {validationFitnessPlan
                                                        ? '...'
                                                        : fitnessPlan
                                                            ? fitnessPlan.fitness_program.name
                                                            : 'Žádný'}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        {!(isCoach || isTrainingPlan) && (
                            <div className='col-md-6 h-mb-2'>
                                <OverviewList
                                    color='blue'
                                    button='Stravovací plán'
                                    buttonIcon='knife-fork-black'
                                    route={routesURLs.mealPlan}
                                    items={[
                                        {
                                            name: 'Tvůj jídelníček',
                                            route: routesURLs.mealPlanMenu,
                                        },
                                        {
                                            name: 'Nákupní seznam',
                                            route: routesURLs.mealPlanShoppingList,
                                        },
                                        {
                                            name: 'Instrukce',
                                            route: 'https://www.strongbeauty.cz/clenska-aplikace-5/instrukce-stravovaci-plan/',
                                            target: '_blank',
                                        },
                                    ]}
                                />
                            </div>
                        )}
                        {fitnessPlanWeeksNew && fitnessPlanWeeksNew.length && (
                            <div className='col-md-6 h-mb-2'>
                                <OverviewList
                                    color='pink'
                                    button='Tréninkový plán'
                                    buttonIcon='abs-black'
                                    route={routesURLs.fitnessPlan}
                                    items={fitnessPlanWeeksNew?.[fitnessPlanWeeksNew?.length - 1].days.map(day => ([...day.trainings.map(training => ({
                                        name: training.name,
                                        route: routesURLs.trainingDetail.replace(':id', training.training_id),
                                    }))])).flat(1)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <News />
            <Tutorial isOpen={showTutorial} close={() => setShowTutorial(false)} />
        </>
    );
};

export default Overview;
