import React, { useState } from 'react';
import { useEffect } from 'react';
import { mutateCallback } from 'swr/dist/types';

import { FitnessWeekDayTraining, FitnessWeekNew } from '../moduls/trainings';
import MissedOneTrainingsModal from '../pages/FitnessPlan/SkippedTrainingsModals/MissedOneTrainingsModal';
import MissedSomeTrainingsModal from '../pages/FitnessPlan/SkippedTrainingsModals/MissedSomeTrainingsModal';
import MissedManyTrainingsModal from '../pages/FitnessPlan/SkippedTrainingsModals/MissedManyTrainingsModal';

const useCheckSkippedTrainings = (
    weeksData: FitnessWeekNew[] | undefined,
    mutateFitnessWeeks: (
        data?: FitnessWeekNew[] | Promise<FitnessWeekNew[]> | mutateCallback<FitnessWeekNew[]> | undefined,
        shouldRevalidate?: boolean,
    ) => Promise<FitnessWeekNew[] | undefined>,
) => {
    const [skippedTrainings, setSkippedTrainings] = useState<FitnessWeekDayTraining[]>([]);
    const [missedOneTrainingModalOpen, setMissedOneTrainingModalOpen] = useState(false);
    const [missedSomeTrainingsModalOpen, setMissedSomeTrainingsModalOpen] = useState(false);
    const [missedManyTrainingsModalOpen, setMissedManyTrainingsModalOpen] = useState(false);

    useEffect(() => {
        if (!weeksData) return;

        const checkSkippedTrainings = () => {
            const nowMidnight = new Date();
            nowMidnight.setUTCHours(0, 0, 0, 0);

            const skippedTrainings = weeksData
                .flatMap((week) => week.days)
                .flatMap((day) => day.trainings)
                .filter(
                    (training) =>
                        new Date(training.date) < nowMidnight && !training.done_at && training.skipped != true,
                );

            setSkippedTrainings(skippedTrainings);

            if (skippedTrainings.length === 1) {
                setMissedOneTrainingModalOpen(true);
                return;
            }

            if (skippedTrainings.length > 4) {
                setMissedManyTrainingsModalOpen(true);
                return;
            }

            if (skippedTrainings.length > 1) {
                setMissedSomeTrainingsModalOpen(true);
                return;
            }
        };

        checkSkippedTrainings();
    }, [weeksData]);

    return {
        oneTraingMissedModal: (
            <MissedOneTrainingsModal
                weeksData={weeksData}
                isOpen={missedOneTrainingModalOpen}
                training={skippedTrainings.at(0)}
                mutateFitnessWeeks={mutateFitnessWeeks}
                setClose={() => {
                    setMissedOneTrainingModalOpen(false);
                }}
            />
        ),
        someTrainingsMissedModal: (
            <MissedSomeTrainingsModal
                isOpen={missedSomeTrainingsModalOpen}
                skippedTrainings={skippedTrainings}
                moveFrom={skippedTrainings?.at(0)?.date}
                setClose={() => {
                    setMissedSomeTrainingsModalOpen(false);
                }}
                mutateFitnessWeeks={mutateFitnessWeeks}
            />
        ),
        manyTrainingsMissedModal: (
            <MissedManyTrainingsModal
                isOpen={missedManyTrainingsModalOpen}
                moveFrom={skippedTrainings?.at(0)?.date}
                setClose={() => {
                    setMissedManyTrainingsModalOpen(false);
                }}
                mutateFitnessWeeks={mutateFitnessWeeks}
            />
        ),
    };
};

export default useCheckSkippedTrainings;
