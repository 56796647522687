import React from 'react';
import { routesURLs } from '../../../Routes';
import BackButton from '../../UI/Button/BackButton';
import Button from '../../UI/Button/Button';
import Tagline from '../../UI/Tagline/Tagline';
import './TrainingTitle.scss';
import { ReactComponent as IconAvatar } from '../../../assets/images/icons/ic-avatar-black.svg';
import { ReactComponent as IconTime } from '../../../assets/images/icons/ic-timer-black.svg';
import { ColorsType, TrainerNameType, TrainingPlaceType, TrainingType } from '../../../services/types';
import { trainerImage } from '../../../services/utils';

const TrainingTitle: React.FC<{
  training: TrainingType;
  color: ColorsType;
  name: string;
  place: TrainingPlaceType;
  openVideo: () => void;
}> = ({ training, place, name, color, openVideo }) => {
  return (
    <div className={`training-title-wrapper is-${color}`}>
      <div className="container">
        <div className="training-title">
          <div className="training-title-back-text">{name}</div>
          <img
            src={trainerImage[training.coach_name as TrainerNameType]}
            alt={training.coach_name}
            className="training-title-image"
          />
          <BackButton to={routesURLs.fitnessPlan} />
          <Tagline size="small">{trainingPlace[place]}</Tagline>
          <h1>{name}</h1>
          <Button onClick={openVideo} color="white" size="small" icon="play-black" className="h-mr-075">
            Přehrát video
          </Button>
          <div className="training-title-tag h-mr-075 h-mb-sm-1">
            <IconAvatar />
            <strong>Koučka:</strong>
            {training.coach_name}
          </div>
          {!!training.duration && (
            <div className="training-title-tag">
              <IconTime />
              <strong>Délka tréninku:</strong>
              {training.duration}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const trainingPlace = {
  HOME: 'Trénink doma',
  FITNESS: 'Trénink ve fitku',
};

export default TrainingTitle;
