import { format } from 'date-fns';
import { mutateCallback } from 'swr/dist/types';
import { FitnessWeekNew, updateFitnessWeeks } from '../moduls/trainings';

const useReplanToToday = (
    mutateFitnessWeeks: (
        data?: FitnessWeekNew[] | Promise<FitnessWeekNew[]> | mutateCallback<FitnessWeekNew[]> | undefined,
        shouldRevalidate?: boolean,
    ) => Promise<FitnessWeekNew[] | undefined>,
) => {
    const replanToToday = async (weeksData: FitnessWeekNew[], trainingId: number) => {
        const currentWeeks = weeksData
            ?.flatMap((week) => week.days)
            .flatMap((day) => day.trainings)
            .map((training) => ({
                id: parseInt(training.id),
                date: training.date,
                slot: parseInt(training.slot),
            }));

        const todaysTrainings = currentWeeks?.filter((tr) => tr.date === format(new Date(), 'Y-MM-dd'));

        const trainingToMove = currentWeeks?.find((tr) => tr.id === trainingId);

        if (trainingToMove == null || currentWeeks == null || todaysTrainings == null) {
            return;
        }

        trainingToMove.date = new Date().toISOString();
        trainingToMove.slot = todaysTrainings?.length ?? 0;

        await updateFitnessWeeks([trainingToMove]).then((res) => {
            mutateFitnessWeeks();
        });
    };

    return replanToToday;
};

export default useReplanToToday;
