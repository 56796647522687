import { cache } from 'swr';
import { LoginFormTypes, PassRecoveryFormTypes, PassResetFormTypes } from '../components/Form/form-types';
import api from '../services/api';
import { deleteCookie, getCookie, setCookie } from '../services/utils';
import { getProfile } from './profile';

export const login = (data: LoginFormTypes) => {
    return api.post('/login', data).then(async (res) => {
        const token = res.data.data.token;
        const refreshToken = res.data.data.refresh_token;
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        setCookie('jid', token, 30);
        setCookie('refreshToken', refreshToken, 30);
        const profile = await getProfile();
        cache.set('profile', profile);
        return profile;
    });
};

export const refreshToken = async () => {
    const token = getCookie('jid');
    const refreshToken = getCookie('refreshToken');

    if (token && refreshToken) {
        await api
            .post(
                '/refresh',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                },
            )
            .then(async (res) => {
                const token = res.data.data.token;
                const refreshToken = res.data.data.refresh_token;
                api.defaults.headers['Authorization'] = `Bearer ${token}`;
                setCookie('jid', token, 30);
                setCookie('refreshToken', refreshToken, 30);
            })
            .catch(() => {
                deleteCookie('jid');
                deleteCookie('refreshToken');
                window.router.replace('/');
            });
        return true;
    }

    return false;
};

export const logout = () => {
    return api.post('/logout').then((_) => {
        deleteCookie('jid');
        deleteCookie('refreshToken');
    });
};

export const passwordRecovery = (data: PassRecoveryFormTypes) => {
    return api.post('/forgotten-password/send-email', data);
};

export const passwordReset = (data: PassResetFormTypes) => {
    return api.post('/forgotten-password/reset-password', data);
};
