import { TrainingPhaseType } from '../moduls/trainings';

export type ColorsType = 'yellow' | 'pink' | 'blue' | 'purple' | 'gray' | 'grey' | 'black';
export type TrainingPlaceType = 'HOME' | 'FITNESS';

export interface UserSetupTypes {
    height: number;
    weight: number;
    diet_preference: string;
    place?: 0 | 1;
    specific_situation?: 'pregnant' | 'after_childbirth' | 'none';
    fitness_level?: 'beginner' | 'intermediate' | 'advanced' | null;
    // target: 'WEIGHT_REDUCTION' | 'BODY_SHAPING' | 'BUILDING_MUSCLE_MASS' | 'WEIGHT_GAIN';
    // frequency: '' | 'THREE_TIMES' | 'FOUR_TIMES';
    // diet_preference: 'VEGAN' | 'VEGETARIAN' | 'EAT_ALL';
}

export interface ProfileDataType {
    access_category: 'MEMBER_SECTION' | 'COACHING' | 'TRAINING_PLAN';
    training_plan_id: number;
    diet_plan_id: number | null;
    email: string;
    fitness_plan_id: number | null;
    fitness_programs_count: number;
    fitness_trainings_count: number;
    name: string;
    place: 0 | 1 | 2 | null;
    profile_photo: string | null;
    support_box: boolean;
    is_testing: boolean;
    tutorial_seen: boolean;
    user_questionnaire: null | {
        diet_preference: 'VEGAN' | 'VEGETARIAN' | 'EAT_ALL';
        height: number;
        weight: number;
    };
}

export interface ExerciseType {
    id: number;
    name: string;
    image: string | null;
    video: string | null;
    video_mobile_url: string | null;
    video_url: string | null;
    description: string;
    instructions: string;
    repetitions: number | null;
    repetitions_unit: string;
    series: number;
    pause: number;
    duration?: number;
}

export interface TrainingType {
    name: string;
    image: string | null;
    coach_name: string;
    duration: string | null;
    music: string;
    video: string | null;
    video_mobile: string | null;
    video_mobile_url: string | null;
    video_url: string | null;
    series: { type: TrainingPhaseType | null; exercises: { exercise_id: number; series: number }[] }[];
    exercises: ExerciseType[];
}

export interface SubscriptionType {
    id: number;
    name: string;
    price: number;
    currency: 'CZK' | 'USD' | 'EUR';
    valid_to: string;
    next_payment_at: string | null;
}

export interface SubscriptionDataType {
    first_name: string | null;
    last_name: string | null;
    phone: string | null;
    ic: string | null;
    dic: string | null;
    company: string | null;
    address_street: string | null;
    address_city: string | null;
    address_zip: string | null;
    address_country: string | null;
    countries: {
        code: string;
        name: string;
    }[];
}

export enum DishCoursesType {
    BREAKFAST = 'Snídaně',
    SNACK_1 = 'Svačina 1',
    LUNCH = 'Oběd',
    SNACK_2 = 'Svačina 2',
    DINNER = 'Večeře',
}

export type TrainingKeysType =
    | 'training_1'
    | 'training_2'
    | 'training_3'
    | 'training_4'
    | 'training_belly'
    | 'training_cardio_hiit'
    | 'training_cardio_liss';

export interface TrainingDataType {
    name: string;
    instructions: string;
    frequency: 'THREE_TIMES' | 'FOUR_TIMES';
    place: 'HOME' | 'FITNESS';
    plan_file: string | null;
    from: null | Date;
    to: null | Date;
    training_1: TrainingType;
    training_2: TrainingType;
    training_3: TrainingType;
    training_4: TrainingType;
    training_belly: TrainingType;
    training_cardio_hiit: TrainingType;
    training_cardio_liss: TrainingType;
}

export interface MealOverviewDataType {
    name: string;
    instructions: string;
    nutrients_value: number;
    proteins_value: number;
    fats_value: number;
    carbohydrates_value: number;
    from: null | Date;
    to: null | Date;
    daily_allotment: {
        name: string;
        amount: string;
    }[];
    weekly_allotment: {
        name: string;
        amount: string;
    }[];
    video: string | null;
}

export interface MealWeekRecipeType {
    id: number;
    name: string;
    image: string;
    type: number;
}

export interface MealWeekRecipesType {
    favorite_recipes: MealWeekRecipeType[];
    recipes: MealWeekRecipeType[];
    selected_recipes: MealWeekRecipeType[][];
    diet_file: string | null;
    from: null | Date;
    to: null | Date;
}

export interface ShoppingListItemType {
    name: string;
    amount: string;
}

export interface ShoppingListType {
    proteins: ShoppingListItemType[];
    carbohydrates: ShoppingListItemType[];
    healthy_fats: ShoppingListItemType[];
    fruit: ShoppingListItemType[];
    dairy_products: ShoppingListItemType[];
    vegetables: ShoppingListItemType[];
    legumes: ShoppingListItemType[];
    condiment: ShoppingListItemType[];
    dry_spices: ShoppingListItemType[];
    shopping_list_file: null;
}

export interface Ingredient {
    name: string;
    amount: string;
    group_name: string;
    group_amount: string;
}

export interface RecipeDataType {
    id: number;
    name: string;
    image: string | null;
    description: string;
    procedure: string;
    ingredients: Ingredient[];
}

export enum ProfileSetupValues {
    WEIGHT_REDUCTION = 'Redukce hmotnosti',
    BODY_SHAPING = 'Formování postavy',
    BUILDING_MUSCLE_MASS = 'Budování svalové hmoty',
    WEIGHT_GAIN = 'Nabírání hmotnosti',
    THREE_TIMES = '3x týdně',
    FOUR_TIMES = '4x týdně',
    HOME = 'Doma',
    FITNESS = 'V posilovně',
    VEGAN = 'Vegan',
    VEGETARIAN = 'Vegetarián',
    EAT_ALL = 'Jím vše',
}

export type TrainerNameType =
    | 'Katy Blažčíková'
    | 'Domi Hostinská'
    | 'Marti Vlášek'
    | 'Reni Markowiczová'
    | 'Iveta Mentl';

export interface PaginatedRequestResponseType {
    links: {
        first: string;
        last: string;
        prev: string;
        next: string;
    };
    meta: {
        current_page: number;
        from: number;
        path: string;
        per_page: number;
        to: number;
    };
}

export enum Months {
    Leden = 0,
    Únor,
    Březen,
    Duben,
    Květen,
    Červen,
    Červenec,
    Srpen,
    Září,
    Říjen,
    Listopad,
    Prosinec,
}

export enum CurrencyType {
    CZK = 'Kč',
    EUR = 'EUR',
    USD = 'USD',
}

declare global {
    interface Window {
        CustomEvent: any;
        _smartsupp: any;
        smartsupp: any;
    }

    interface Element {
        webkitRequestFullscreen: any;
        msRequestFullscreen: any;
        mozRequestFullScreen: any;
    }

    interface Document {
        webkitExitFullscreen: any;
        msExitFullscreen: any;
    }
}
