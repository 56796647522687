import axios from 'axios';
import { deleteCookie, getCookie } from './utils';
import { refreshToken } from '../moduls/auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': 'STRONG_BEAUTY',
        Authorization: `Bearer ${getCookie('jid')}`,
    },
});

api.interceptors.request.use(async function (config) {
    const token = localStorage.getItem('jid');
    if (!config.headers.Authorization) {
        config.headers = { ...config.headers, ...api.defaults.headers };
    }
    return config;
});

api.interceptors.response.use(
    function (response) {
        // MIDDLEWARE
        return response;
    },
    async function (error) {
        if (error.response.status === 401) {
            const originalRequest = error.config;
            if (originalRequest.url === '/refresh') {
                deleteCookie('jid');
                deleteCookie('refreshToken');
                window.router.replace('/');
                return Promise.reject(error);
            }

            if (!originalRequest._retry) {
                originalRequest._retry = true;

                const resp = await refreshToken();

                if (resp) {
                    return api(originalRequest);
                }

                deleteCookie('jid');
                deleteCookie('refreshToken');
                window.router.replace('/');
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    },
);

export default api;

export const apiErrors = {
    100: 'Pokračovat',
    101: 'Změna protokolů',
    102: 'Zpracování',
    103: 'Kontrolní bod',
    200: 'OK',
    201: 'Vytvořeno',
    202: 'Přijato',
    203: 'Nesměrodatná informace',
    204: 'Žádný obsah',
    205: 'Obnovit obsah',
    206: 'Dílčí obsah',
    207: 'S více stavy',
    208: 'Již nahlášeno',
    226: 'Použité IM',
    300: 'Dokument je dostupný na více umístěních',
    301: 'Trvale přesunuto',
    302: 'Dočasně přesunuto',
    303: 'Viz jiné místo',
    304: 'Od posledního požadavku se zdrojový dokument nezměnil',
    305: 'Použijte server proxy',
    307: 'Dočasné přesměrování',
    308: 'Trvalé přesměrování',
    400: 'Chybný požadavek',
    401: 'Omlouváme se, nejste oprávněni k přístupu na tuto stránku',
    402: 'Požadovaná platba',
    403: 'Omlouváme se, ale přístup na tuto stránku je zakázán',
    404: 'Omlouváme se, ale hledanou stránku se nepodařilo najít',
    405: 'Metoda není povolena',
    406: 'Nepřijatelné',
    407: 'Vyžaduje se ověření serverem proxy',
    408: 'Časový limit požadavku vypršel',
    409: 'Konflikt',
    410: 'Dokument již není dostupný',
    411: 'Je vyžadována délka',
    412: 'Předběžná podmínka se nezdařila',
    413: 'Požadavek je příliš rozsáhlý',
    414: 'Požadovaný identifikátor URI je příliš dlouhý',
    415: 'Nepodporovaný typ média',
    416: 'Požadovaný rozsah nelze uspokojit',
    417: 'Očekávání se nezdařilo',
    418: 'Jsem konvička',
    419: 'Omlouváme se, vaše relace vypršela, obnovte ji prosím a zkuste to znovu',
    421: 'Nesprávně směrovaná žádost',
    422: 'Nezpracovatelná entita',
    423: 'Uzamčeno',
    424: 'Selhání závislosti',
    425: 'Přiliš brzy',
    426: 'Vyžaduje se upgrade',
    427: 'Musíte se nejdříve odhlásit',
    428: 'Je nutná podmínka',
    429: 'Omlouváme se, ale zadáváte příliš mnoho požadavků na naše servery',
    431: 'Hlavičky požadavku jsou přiliš velké',
    451: 'Nedostupné z právních důvodů',
    500: 'Ups, něco se pokazilo na našich serverech',
    501: 'Není implementováno',
    502: 'Chybná brána',
    503: 'Omlouváme se, provádíme údržbu, zkontrolujte to prosím později',
    504: 'Časový limit brány vypršel',
    505: 'Verze HTTP není podporována',
    506: 'Varianta tak= vyjednávat',
    507: 'Nedostatečné úložiště',
    508: 'Zjištěna smyčka',
    510: 'Nerozšířeno',
    511: 'Je vyžadováno ověření v síti',
};
