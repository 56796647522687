import React from 'react';
import { useSWRInfinite } from 'swr';
import Carousel from '../../../components/Layout/Carousel/Carousel';
import NewsSkeleton from '../../../components/Skeletons/NewsSkeleton';
import Tagline from '../../../components/UI/Tagline/Tagline';
import { getNews } from '../../../moduls/news';
import { PaginatedRequestResponseType } from '../../../services/types';
import { createArray } from '../../../services/utils';
import './News.scss';

interface NewsType {
    content: string;
    date: string;
}

interface RequestNewsType extends PaginatedRequestResponseType {
    data: NewsType[];
}

const News = () => {
    const { data, size, setSize } = useSWRInfinite<RequestNewsType>((index) => `/news?page=${index + 1}`, getNews, {
        dedupingInterval: 1000000,
    });

    const isReachingEnd = data ? data.length === 0 || !data[data.length - 1].links.next : false;

    return (
        <div className='overview-news page-row container-row h-pb-4 h-pb-sm-2'>
            <div className='overview-news-title'>
                <Tagline className='h-color-medium-gray' size='small'>
                    Novinky
                </Tagline>
                <hr />
            </div>
            <Carousel
                settings={{
                    className: 'news-carousel',
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    beforeChange: (_, next) => {
                        if (!isReachingEnd && next % 8 === 0 && next > 0) {
                            setSize(size + 1);
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            },
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                adaptiveHeight: true,
                            },
                        },
                    ],
                }}
            >
                {data !== undefined
                    ? data?.map((page) =>
                        page.data.map((news, index) => {
                            const date = new Date(news.date);
                            return (
                                <div key={index} className='overview-news-item'>
                                    <h4>{`${date.getDate().toString()}. ${(date.getMonth()+1).toString()}. ${date.getFullYear().toString()}`}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: news.content }}></p>
                                </div>
                            );
                        }),
                    )
                    : createArray(4).map((item) => <NewsSkeleton key={item} />)}
            </Carousel>
        </div>
    );
};

export default News;
