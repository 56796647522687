import useSWR from 'swr';
import { FitnessWeek, FitnessWeekNew, getFitnessWeeks, getFitnessWeeksNew } from '../moduls/trainings';

const useFitnessWeeks = () => {
    const data = useSWR<FitnessWeek[]>('fitness-week', getFitnessWeeks);
    return data;
};

export default useFitnessWeeks;

export const useFitnessWeeksNew = () => {
    const data = useSWR< FitnessWeekNew[]>('fitness-week-v2', getFitnessWeeksNew);
    return data;
}