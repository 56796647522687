import React from 'react';
import './Loader.scss';

const AppLoader = () => {
    return (
        <div className="loader">
            <div className="app-loader-spinner"></div>
        </div>
    );
};

export default AppLoader;
