import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useWakeLock } from 'react-screen-wake-lock';
import TrainingRow from '../../components/Training/TrainingRow/TrainingRow';
import TrainingTitle from '../../components/Training/TrainingTitle/TrainingTitle';
import Accordion from '../../components/UI/Accordion/Accordion';
import Loader from '../../components/UI/Loader/Loader';
import Toggler from '../../components/UI/Toggler/Toggler';
import VideoComponent from '../../components/VideoComponent/VideoComponent';
import VideoComponentSimple from '../../components/VideoComponent/VideoComponentSimple';
import useProfile from '../../hooks/useProfile';
import {
    FitnessWeek,
    FitnessWeekTraining,
    getFitnessWeeks,
    getTraining,
    trainingTypes,
    updateTraining,
} from '../../moduls/trainings';
import { TrainingType } from '../../services/types';
import { classes, setNoScroll, trainingInfo } from '../../services/utils';
import { ReactComponent as ChevronDown } from '../../assets/images/icons/ic-chevron-down-black.svg';
import './TrainingPlanDetail.scss';
import Button from '../../components/UI/Button/Button';
import { ReactComponent as Check } from '../../assets/images/icons/ic-check.svg';
import { format } from 'date-fns';
import useNotification from '../../hooks/useNotification';
import { routesURLs } from '../../Routes';

const TrainingPlanDetail = () => {
    const [openVideo, setOpenVideo] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [mobileVideoWithLoop, setMobileVideoWithLoop] = useState(false);
    const [videoId, setVideoId] = useState<number | null>(null);
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const { data: profile } = useProfile();
    const { open: openNotification } = useNotification();
    const { isSupported, request, release } = useWakeLock();

    const { mutate: mutateFitnessWeeks } = useSWR<FitnessWeek[]>('fitness-plan', getFitnessWeeks, {
        revalidateOnFocus: false,
    });
    const slug = 'training_cardio_hiit';

    const { data } = useSWR<
        { training_0: TrainingType; training_1: TrainingType } & Omit<
            FitnessWeekTraining,
            'training_0_id' | 'training_1_id'
        >
    >(['training-detail', id], profile ? getTraining : null);

    const profilePlace = (profile?.place === 2 ? 0 : profile?.place) ?? 0;
    const training = data?.[`training_${+profilePlace}` as 'training_0' | 'training_1'];

    useEffect(() => {
        setNoScroll(openVideo);
        return () => {
            setNoScroll(false);
        };
    }, [openVideo]);

    useEffect(() => {
        if (data) {
            setIsDone(!!data.done_at);
        }
    }, [data]);

    useEffect(() => {
        const onVisibilityChange = () => {
            if (isSupported) {
                request();
            }
        };

        onVisibilityChange();

        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
            if (isSupported) {
                release();
            }
        };
        // eslint-disable-next-line
    }, []);

    const openVideoPlayer = (mobileVideoLoop: boolean, index: number | null) => {
        setVideoId(index);
        setMobileVideoWithLoop(mobileVideoLoop);
        setOpenVideo(true);
    };

    if (training === undefined) return <Loader />;

    const flatedSeries = training.series
        .map((serie) => serie.exercises.map((item) => ({ ...item, type: serie.type })))
        .flat(1);
    const color = trainingTypes[data?.color.toString() ?? 0].color;

    return (
        <div>
            <TrainingTitle
                training={training}
                name={data?.name ?? ''}
                place={(profile?.place ?? 0) == 1 ? 'FITNESS' : 'HOME'}
                color={color}
                openVideo={() => openVideoPlayer(false, null)}
            />
            <div className="container h-py-3">
                {training.video_url ? (
                    <VideoComponentSimple
                        open={openVideo}
                        closeVideo={() => setOpenVideo(false)}
                        training={training}
                        color={color}
                    />
                ) : (
                    <VideoComponent
                        trainingId={data?.id ?? 0}
                        open={openVideo}
                        closeVideo={() => setOpenVideo(false)}
                        loop={mobileVideoWithLoop}
                        training={training}
                        color={color}
                        videoId={videoId}
                    />
                )}
                <div>
                    <Toggler>
                        {({ open, setOpen }) => (
                            <>
                                <h3
                                    onClick={() => setOpen(!open)}
                                    className={classes('training-title-toggle', { 'is-open': open })}
                                >
                                    Zahřátí <ChevronDown />
                                </h3>
                                <Accordion open={open} disableOverflow className="h-mb-2">
                                    {training.exercises
                                        .filter((exercise) =>
                                            flatedSeries.some(
                                                (serie) => serie.exercise_id === exercise.id && serie.type === 1,
                                            ),
                                        )
                                        .map((exercise, index) => (
                                            <TrainingRow
                                                key={exercise.name + index}
                                                icon={trainingInfo[slug].icon}
                                                image={exercise.image}
                                                title={exercise.name}
                                                series={exercise.series}
                                                description={exercise.instructions}
                                                repetitions={exercise.repetitions}
                                                repetitions_unit={exercise.repetitions_unit}
                                                playVideoTrigger={
                                                    exercise.video_url
                                                        ? () => openVideoPlayer(true, exercise.id)
                                                        : undefined
                                                }
                                                color={color}
                                                // tag={{ color: 'yellow', text: 'A' }}
                                            />
                                        ))}
                                </Accordion>
                            </>
                        )}
                    </Toggler>
                    <div className="h-mb-2">
                        <h3 className="training-title-toggle is-title">Trénink</h3>
                        {training.exercises
                            .filter((exercise) =>
                                flatedSeries.some((serie) => serie.exercise_id === exercise.id && !serie.type),
                            )
                            .map((exercise, index) => (
                                <TrainingRow
                                    key={exercise.name + index}
                                    icon={trainingInfo[slug].icon}
                                    image={exercise.image}
                                    title={exercise.name}
                                    series={exercise.series}
                                    description={exercise.instructions}
                                    repetitions={exercise.repetitions}
                                    repetitions_unit={exercise.repetitions_unit}
                                    playVideoTrigger={
                                        exercise.video_url ? () => openVideoPlayer(true, exercise.id) : undefined
                                    }
                                    color={color}
                                    // tag={{ color: 'yellow', text: 'A' }}
                                />
                            ))}
                    </div>
                    <Toggler>
                        {({ open, setOpen }) => (
                            <>
                                <h3
                                    onClick={() => setOpen(!open)}
                                    className={classes('training-title-toggle', { 'is-open': open })}
                                >
                                    Strečink <ChevronDown />
                                </h3>
                                <Accordion open={open} disableOverflow className="h-mb-2">
                                    {training.exercises
                                        .filter((exercise) =>
                                            flatedSeries.some(
                                                (serie) => serie.exercise_id === exercise.id && serie.type === 2,
                                            ),
                                        )
                                        .map((exercise, index) => (
                                            <TrainingRow
                                                key={exercise.name + index}
                                                icon={trainingInfo[slug].icon}
                                                image={exercise.image}
                                                title={exercise.name}
                                                series={exercise.series}
                                                description={exercise.instructions}
                                                repetitions={exercise.repetitions}
                                                repetitions_unit={exercise.repetitions_unit}
                                                playVideoTrigger={
                                                    exercise.video_url
                                                        ? () => openVideoPlayer(true, exercise.id)
                                                        : undefined
                                                }
                                                color={color}
                                                // tag={{ color: 'yellow', text: 'A' }}
                                            />
                                        ))}
                                </Accordion>
                            </>
                        )}
                    </Toggler>
                </div>
                {data && (
                    <div className="h-text-center h-mt-4">
                        <Button
                            className={[
                                `fitness-plan-stone h-text-size-default  is-yellow`,
                                isDone ? 'is-done' : undefined,
                            ]
                                .filter(Boolean)
                                .join(' ')}
                            onClick={() => {
                                setIsDone(!isDone);
                                updateTraining(data?.id, { done_at: isDone ? null : format(new Date(), 'Y-MM-dd') })
                                    .then(async (res) => {
                                        const mutatedData = await mutateFitnessWeeks();
                                        if (!mutatedData) return;
                                        if (res.status === 200 && res.data.message === 'Fitness plan completed.') {
                                            history.push(routesURLs.fitnessPlan + '?finish-modal=1');
                                        }
                                    })
                                    .catch(() => {
                                        openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                                    });
                            }}
                        >
                            Mám hotovo
                            <span>
                                <Check />
                            </span>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrainingPlanDetail;
