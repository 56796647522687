import './SkippedTrainingsModal.scss';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { mutateCallback } from 'swr/dist/types';
import { mutate } from 'swr';

import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import {
    FitnessWeek,
    FitnessWeekDayTraining,
    FitnessWeekNew,
    updateFitnessWeeks,
    updateTraining,
} from '../../../moduls/trainings';
import useNotification from '../../../hooks/useNotification';
import { routesURLs } from '../../../Routes';
import useReplanToToday from '../../../hooks/useReplanToToday';

interface Props {
    isOpen: boolean;
    training?: FitnessWeekDayTraining;
    mutateFitnessWeeks: (
        data?: FitnessWeekNew[] | Promise<FitnessWeekNew[]> | mutateCallback<FitnessWeekNew[]> | undefined,
        shouldRevalidate?: boolean,
    ) => Promise<FitnessWeekNew[] | undefined>;
    weeksData?: FitnessWeekNew[] | undefined;
    setClose: () => void;
}

const MissedOneTrainingsModal = (props: Props) => {
    const training = props.training;
    const id = parseInt(props.training?.training_id ?? '0');
    const [isDone, setIsDone] = useState(training?.done_at != null);
    const history = useHistory();
    const { open: openNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const replanToToday = useReplanToToday(props.mutateFitnessWeeks);

    return (
        <Modal isOpen={props.isOpen} close={() => {}} hideCloseButton>
            <h3 className="h-mb-3">Vynechala jsi předchozí trénink. Co bys chtěla udělat?!</h3>
            <div className="btn-column">
                <Button
                    size="small"
                    loading={isLoading}
                    color="gray"
                    onClick={() => {
                        setIsLoading(true);
                        updateTraining(id, { done_at: isDone ? null : training?.date })
                            .then(async (res) => {
                                await mutate('fitness-plan').then((data: FitnessWeek[]) => {
                                    if (res.status === 200 && res.data.message === 'Fitness plan completed.') {
                                        history.push(routesURLs.fitnessPlan + '?finish-modal=1');
                                    }
                                });
                                await props.mutateFitnessWeeks();
                                props.setClose();
                            })
                            .catch(() => {
                                openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                                setIsDone(isDone);
                            });
                    }}
                >
                    Odcvičila jsem ho, jen neodškrtla
                </Button>
                <Button
                    size="small"
                    color="gray"
                    loading={isLoading}
                    onClick={() => {
                        setIsLoading(true);

                        updateTraining(id, { done_at: null, skipped: true })
                            .then(async (res) => {
                                await mutate('fitness-plan');
                                await props.mutateFitnessWeeks();
                                props.setClose();
                            })
                            .catch(() => {
                                openNotification('Nastala chyba. Opakujte, prosím, akci později.');
                                setIsDone(isDone);
                            });
                    }}
                >
                    Vynechat ho
                </Button>
                <Button
                    size="small"
                    color="gray"
                    loading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        await replanToToday(props.weeksData ?? [], parseInt(props.training?.id ?? '0'));
                        props.setClose();
                    }}
                >
                    Přesunout ho na dnešek
                </Button>
            </div>
        </Modal>
    );
};

export default MissedOneTrainingsModal;
