import React, { useEffect, useRef, useState } from 'react';
import useProfile from '../../hooks/useProfile';
import './FitnessPlan.scss';
import Modal, { ModalPropsType } from '../../components/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import { setFitnessPlanFirstDay, trainingTypes, updateFitnessWeeks } from '../../moduls/trainings';
import useFitnessPlan from '../../hooks/useFitnessPlan';
import { Formik, Form } from 'formik';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronRight } from '../../assets/images/icons/ic-chevron-right-black.svg';
import { ReactComponent as ChevronLeft } from '../../assets/images/icons/ic-chevron-left-black.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/ic-calendar.svg';
import FitnessPlanStone from './FitnessPlanStone';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import FitnessPlanStonePlaceholder from './FitnessPlanStonePlaceholder';
import Loader from '../../components/UI/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { routesURLs } from '../../Routes';
import { useFitnessWeeksNew } from '../../hooks/useFitnessWeeks';
import useCheckSkippedTrainings from '../../hooks/useCheckSkippedTrainings';
import useReplanToToday from '../../hooks/useReplanToToday';

let timeout: NodeJS.Timeout;
let navigateTimeout: NodeJS.Timeout;

const FitnessPlan = () => {
    const [init, setInit] = useState(false);
    const [draggingId, setDraggingId] = useState<number>();
    const [instructionModal, setInstructionModal] = useState(false);
    const [conditionsModal, setConditionsModal] = useState(false);
    const [conditionsShown, setConditionsShown] = useState(false);
    const [finishProgramModal, setFinishProgramModal] = useState(false);
    const [selectDayModal, setSelectDayModal] = useState(false);
    const [activeWeek, setActiveWeek] = useState(0);
    const [weekDays] = useState([
        { title: 'PO', day: 0 },
        { title: 'ÚT', day: 1 },
        { title: 'ST', day: 2 },
        { title: 'ČT', day: 3 },
        { title: 'PÁ', day: 4 },
        { title: 'SO', day: 5 },
        { title: 'NE', day: 6 },
    ]);
    const [sortedWeekDays, setSortedWeekDays] = useState<
        {
            title: string;
            day: number;
        }[]
    >(weekDays);

    const history = useHistory();
    const { data: profile } = useProfile();
    const { data: fitnessPlan, mutate: mutateFitnessPlan } = useFitnessPlan(
        profile?.fitness_plan_id as number | undefined,
    );

    const { data: data, mutate: mutateFitnessWeeks } = useFitnessWeeksNew();

    const replanToToday = useReplanToToday(mutateFitnessWeeks);

    const currentWeekData = data?.[activeWeek];

    const [weekData, setWeekData] = useState<number[][]>();

    const { oneTraingMissedModal, manyTrainingsMissedModal, someTrainingsMissedModal } = useCheckSkippedTrainings(
        data,
        mutateFitnessWeeks,
    );

    useEffect(() => {
        return () => {
            if(timeout) {
                clearTimeout(timeout)
            }
            if (navigateTimeout) {
                clearTimeout(navigateTimeout);
            }
        }
    }, []);

    useEffect(() => {
        if (data) {
            setActiveWeek(data.length - 1);
        }
    }, [data]);

    useEffect(() => {
        setInit(true);
        if (init && !currentWeekData && !conditionsShown) {
            setConditionsModal(true);
            setConditionsShown(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWeek]);

    useEffect(() => {
        if (history.location.search.indexOf('finish-modal=1') > -1) {
            setFinishProgramModal(true);
        }
    }, [history.location]);

    useEffect(() => {
        if (fitnessPlan?.day === null) {
            setSelectDayModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fitnessPlan]);

    useEffect(() => {
        if (currentWeekData && sortedWeekDays?.length) {
            setWeekData(
                currentWeekData.days.map((day) =>
                    day.trainings.sort((a, b) => parseInt(a.slot) - parseInt(b.slot)).map((training) => parseInt(training.id)),
                ),
            );
        }
    }, [currentWeekData, sortedWeekDays]);

    const changePosition = (removeIndex: [number, number], addIndex: [number, number]) => {
        if (!weekData) return;

        let tempWeekData = [...weekData];
        const id = weekData![removeIndex[0]][removeIndex[1]] ?? weekData![removeIndex[0]][removeIndex[1] - 1];

        // remove id
        tempWeekData[removeIndex[0]] = tempWeekData[removeIndex[0]].filter((_, index) => index !== removeIndex[1]);

        // insert id
        const tempAddWeekData = [...tempWeekData[addIndex[0]]];
        if (!tempAddWeekData.find((_) => _ === id)) {
            tempAddWeekData.splice(addIndex[1], 0, id);
            tempWeekData[addIndex[0]] = tempAddWeekData;
        }

        setWeekData(tempWeekData);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const sortedData = tempWeekData
                ?.map((dayData, index) => {
                    return dayData.map((id, i) => {
                        const day = sortedWeekDays?.[index].day;
                        const date = (new Date(currentWeekData?.days[day].date ?? '')).toISOString();
                        return { id, date: date, slot: i + 1 };
                    });
                })
                .flat(1);
            sortedData && updateFitnessWeeks(sortedData);
        }, 2000);
    };

    const getFormatedDate = (date: string, length?: number, onlyDay?: boolean) => {
        if (!date) return null;
        const _date = new Date(date);
        if (!length) {
            return onlyDay ? `${_date.getDate()}`:`${_date.getDate()}. ${_date.getMonth() + 1}`;
        } else {
            const _dateEnd = new Date(date);
            _dateEnd.setDate(_dateEnd.getDate() + length);
            return `${_date.getDate()}. ${_date.getMonth() + 1} - ${_dateEnd.getDate()}. ${_dateEnd.getMonth() + 1}`;
        }
    };

    return (
        <>
            <div className='fitness-plan dashboard-grid page-row container'>
                <div className='dashboard-side-text'>
                    <div className='overview-text'>
                        <h1>Tréninkový plán</h1>

                        {Array.isArray(data) && data.length > 0 && fitnessPlan && fitnessPlan?.day !== null && (
                            <div className='fitness-plan-week-select h-mb-3'>
                                <button
                                    onClick={() => {
                                        const nextWeek = (activeWeek + fitnessPlan?.fitness_program.weeks - 1) %
                                            fitnessPlan?.fitness_program.weeks;
                                        console.log(nextWeek)
                                        if(nextWeek > data.length-1) {
                                            setConditionsModal(true);
                                        } else {
                                            setActiveWeek(nextWeek);
                                        }
                                    }}
                                >
                                    <ChevronLeft />
                                </button>
                                <span>
                                    Týden : {activeWeek + 1}/{fitnessPlan?.fitness_program.weeks}
                                </span>
                                <button
                                    className='h-mr-1'
                                    onClick={() => {
                                        const nextWeek = (activeWeek + 1) % fitnessPlan?.fitness_program.weeks;
                                        if(nextWeek > data.length-1) {
                                            setConditionsModal(true);
                                        } else {
                                            setActiveWeek(nextWeek);
                                        }
                                    }}
                                >
                                    <ChevronRight />
                                </button>
                                <CalendarIcon />
                                <div>
                                    {getFormatedDate(data[activeWeek]?.first_day, 6)}
                                </div>
                            </div>
                        )}

                        <div className='fitness-plan-buttons'>
                            <Button onClick={() => setInstructionModal(true)}>Zobrazit instrukce</Button>

                            {fitnessPlan?.pdf && (
                                <a
                                    href={fitnessPlan.pdf}
                                    className='button is-black'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Stáhnout informativní PDF
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                {Array.isArray(data) && data.length > 0 && fitnessPlan && fitnessPlan?.day !== null && (
                    <DndProvider options={HTML5toTouch}>
                        <div className='dashboard-content'>
                            {data && Array.isArray(data[activeWeek]?.days) ? (
                                <div>
                                    {weekData && weekData.map((trainings, index) => {
                                        const day = data[activeWeek].days[index];
                                        return (
                                            <div key={day.date} className='fitness-plan-item'>
                                                <div className="fitness-plan-date-wrapper">
                                                    <div
                                                        className='fitness-plan-item-day'>{weekDays[index].title ?? ''}</div>
                                                    <div
                                                        className='fitness-plan-item-day-date h-mx-auto'>{getFormatedDate(day.date, undefined, true)}</div>
                                                </div>

                                                <div className='fitness-plan-item-container'>
                                                    {trainings?.map((trainingIndex, i) => {
                                                        const training = currentWeekData?.days.map((row) => {
                                                            return row.trainings.find((training) => parseInt(training.id) === trainingIndex);
                                                        }).filter((item) => !!item)[0];
                                                        if (!training) return null;
                                                        const id = parseInt(training?.training_id ?? 0);
                                                        return (
                                                            <FitnessPlanStone
                                                                id={id}
                                                                index={[index, i]}
                                                                key={training.id}
                                                                color={trainingTypes[training.color.toString()].color}
                                                                done={!!training?.done_at}
                                                                changePosition={changePosition}
                                                                setDraggingId={setDraggingId}
                                                                stoneOpacity={draggingId === id ? 0 : 1}
                                                                trainingDate={training.date}
                                                                onTrainingReplan={async () => {
                                                                    replanToToday(data, parseInt(training.id));
                                                                }}
                                                            >
                                                                {training?.name}
                                                            </FitnessPlanStone>
                                                        );
                                                    })}
                                                    <FitnessPlanStonePlaceholder
                                                        index={[index, day.trainings.length]}
                                                        changePosition={changePosition}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </DndProvider>)}
            </div>
            {oneTraingMissedModal}
            {someTrainingsMissedModal}
            {manyTrainingsMissedModal}
            <Modal isOpen={instructionModal} close={() => setInstructionModal(false)}>
                <div
                    className='h-text-left'
                    dangerouslySetInnerHTML={{ __html: fitnessPlan?.instructions ?? '' }}
                ></div>
            </Modal>
            <Modal isOpen={conditionsModal} close={() => setConditionsModal(false)}>
                <div className='h-text-left'>
                    <h4 className='h-mb-2'>Nový tréninkový týden se ti zde zobrazí po splnění dvou podmínek:</h4>
                    <ol className='h-pl-1'>
                        <li className='h-mb-1'>
                            Všechny tréninky z předchozího týdne máš označené jako odcvičené zelenou fajfkou.
                        </li>
                        <li>Nejdříve nejbližší pondělí.</li>
                    </ol>
                </div>
            </Modal>
            <Modal
                isOpen={finishProgramModal}
                close={() => {
                    history.replace(routesURLs.fitnessPlan);
                    setFinishProgramModal(false);
                }}
            >
                <h3 className='h-mb-1'>Gratulujeme!</h3>
                <p>
                    Zvládla jsi to a dokončila celý Fitness program! Jsme na tebe pyšné a máme pro tebe odměnu. Napiš
                    nám e-mail na <a href='mailto:kontakt@strongbeauty.cz'>kontakt@strongbeauty.cz</a> a dostaneš od nás
                    dáreček.
                </p>
                <p className='h-my-2'>
                    <strong>Jsi namotivovaná pokračovat?</strong>
                </p>
                <Button
                    onClick={() => {
                        setFinishProgramModal(false);
                        navigateTimeout = setTimeout(() => {
                            history.replace(routesURLs.fitnessPrograms);
                        }, 100);
                    }}
                >
                    Vyber si nový fitness program
                </Button>
            </Modal>
            <FitnessPlanSetDayModal
                id={fitnessPlan?.id ?? 0}
                isOpen={selectDayModal}
                close={() => setSelectDayModal(false)}
                onSuccess={() => {
                    return new Promise(async (resolve) => {
                        await mutateFitnessPlan();
                        await mutateFitnessWeeks();
                        resolve(true);
                    });
                }}
            />
        </>
    );
};

export default FitnessPlan;

const FitnessPlanSetDayModal: (props: ModalPropsType & { id: number; onSuccess: () => void }) => JSX.Element = ({
                                                                                                                    id,
                                                                                                                    onSuccess,
                                                                                                                    ...props
                                                                                                                }) => {
    const [date, setDate] = useState<string | null>(null);
    const dateInputRef = useRef<HTMLInputElement>(null);
    const { open: openNotification } = useNotification();

    return (
        <Modal {...props} close={() => {
        }} hideCloseButton>
            <div className='h-pt-2 h-pb-65'>
                <h3>Zvolte počáteční den</h3>
                <p>Vyberte si, kterým dnem v týdnu budeš chtít tréninkový plán začínat.</p>
                <Formik
                    initialValues={{
                        day: '',
                        date: '',
                    }}
                    onSubmit={(values) => {
                        const dateFormat = new Date(date!);
                        return setFitnessPlanFirstDay(id, dateFormat.toISOString())
                            .then(async () => {
                                await onSuccess();
                                props.close();
                            })
                            .catch((err) => {
                                openNotification(err.response.data.message, 'error');
                            });
                    }}
                    validateOnBlur={false}
                >
                    {({ isSubmitting, values }) => (
                        <Form className=''>
                            <div onClick={() => {
                                if (dateInputRef) {
                                    try {
                                        // @ts-ignore
                                        dateInputRef.current!.showPicker();
                                        dateInputRef.current!.focus();
                                        dateInputRef.current!.click();
                                    } catch (error) {
                                    }


                                }
                            }} className='h-text-left h-mx-auto' style={{ maxWidth: '300px' }}>
                                <input ref={dateInputRef} className='h-datepicker-input'
                                       min={new Date().toISOString().split('T')[0]} name='date' type='date'
                                       onChange={(e) => {
                                           setDate(e.target.value);
                                       }} />
                            </div>
                            <div className="fitness-programs-confirm">
                                <Button type='submit' loading={isSubmitting} className='h-mt-2' disabled={date === null}>
                                    Potvrdit výběr
                                </Button>
                                <Button
                                    color='black'
                                    className='h-m-05'
                                    to={routesURLs.dashboard}
                                    onClick={() => {}}
                                >
                                    Zpět
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};
